import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import scrollTo from 'gatsby-plugin-smoothscroll'

import locales from '../../i18n/locales'
import Container from '../container'
import { styleVars, breakPoint } from '../../theme/theme'
import BrandLink from '../link'
import FormSwitch, { StyledSwitch } from '../forms/switch'
import imgLogo from '../../images/logo.svg'
import { Language } from '../../i18n/types/languages'

type Props = {
  intl: intlShape
  darkState: boolean
  changeDarkState: () => void
}

const Header = ({ intl: { locale }, darkState, changeDarkState }: Props) => {
  return (
    <StyledHeader>
      <Container>
        <div className="header-inner">
          <Link to={locales[locale].default ? '/' : `/${locale}/`}>
            <img src={imgLogo} alt="Logo" className="header-logo" />
          </Link>

          <StyledNav>
            <BrandLink tag="button" onClick={() => scrollTo('#why-we')} className="header-link">
              <FormattedMessage id="whyWe" />
            </BrandLink>
            <BrandLink tag="button" onClick={() => scrollTo('#projects')} className="header-link">
              <FormattedMessage id="projects" />
            </BrandLink>
            <BrandLink tag="button" onClick={() => scrollTo('#feedback')} className="header-link">
              <FormattedMessage id="contacts" />
            </BrandLink>
          </StyledNav>

          <div className="header-controls">
            {Object.keys(locales).map((key: Language) => (
              <Link
                key={key}
                to={locales[key].default ? '/' : `/${locales[key].path}`}
                className={key === locale ? 'header-language-btn active' : 'header-language-btn'}
              >
                {locales[key].locale}
              </Link>
            ))}
            <FormSwitch checked={darkState} handleChange={changeDarkState} />
          </div>
        </div>
      </Container>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.dark && styleVars.brandGrayLight};
  background-color: #000;

  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${styleVars.headerHeight};
  }

  .header-logo {
    display: block;
  }

  .header-link {
    color: ${styleVars.brandGrayLight};

    &:hover {
      color: ${styleVars.brandPrimary};
    }
  }

  .header-controls {
    display: flex;
    align-items: center;
  }

  .header-language-btn {
    margin-right: 12px;
    text-decoration: none;
    color: ${styleVars.brandGrayLight};

    &.active {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }
  }

  ${StyledSwitch} {
    margin-left: 12px;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex: auto;
  justify-content: space-between;
  max-width: 432px;
  margin-right: 88px;
  margin-left: 88px;

  @media ${breakPoint.tablet} {
    display: none;
  }
`

export default injectIntl(Header)
