import React from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Container from '../container'
import { breakPoint, styleVars } from '../../theme/theme'
import BrandLink from '../link'
import { resetUl } from '../../theme/mixins'

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <div className="footer-inner">
          <div className="footer-row">
            <div className="footer-col">
              <StyledText light>
                <FormattedMessage id="email" />:
              </StyledText>
              <ul className="footer-list">
                <li>
                  <StyledText bold>
                    <BrandLink tag="a" href="mailto:hello@undef.tech">
                                            hello@undef.tech
                    </BrandLink>
                  </StyledText>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <StyledText light>
                <FormattedMessage id="phone" />:
              </StyledText>
              <ul className="footer-list">
                <li>
                  <StyledText bold>
                    <BrandLink tag="a" href="tel:+79872903668">
                                            +7 (987) 290-36-68
                    </BrandLink>
                  </StyledText>
                </li>
                {/* <li>
                  <StyledText bold>
                    <BrandLink tag="a" href="tel:+78002004598">
                                            +7 (800) 200-4598
                    </BrandLink>
                  </StyledText>
                </li> */}
              </ul>
            </div>
            <div className="footer-col">
              <StyledText light>
                <FormattedMessage id="address" />:
              </StyledText>
              <ul className="footer-list">
                <li>
                  <div className="footer-list-cell">
                    <StyledText bold>
                      <FormattedMessage id="addressCity1" />
                    </StyledText>
                  </div>
                  <div className="footer-list-cell">
                    <StyledText>
                      <FormattedMessage id="addressStreet1" />
                    </StyledText>
                  </div>
                </li>
                {/* Пока один адрес
                 <li>
                  <div className="footer-list-cell"><StyledText bold>
                    <FormattedMessage id="addressCity2"/>
                  </StyledText></div>
                  <div className="footer-list-cell"><StyledText>
                    <FormattedMessage id="addressStreet2"/>
                  </StyledText></div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </StyledFooter>
  )
}

const StyledText = styled.div`
    font-size: ${styleVars.fontSizeSmall};
    color: #fff;

    ${({ bold }) =>
        bold &&
        css`
            font-weight: ${styleVars.fontWeightBold};
        `};

    ${({ light }) =>
        light &&
        css`
            opacity: 0.6;
        `};

    a {
        color: inherit;
    }
`

const StyledFooter = styled.footer`
    margin-top: 172px;
    border-top: 1px solid ${styleVars.brandGrayLight};
    background-color: #000;

    @media ${breakPoint.phone} {
        margin-top: 88px;
    }

    .footer-inner {
        max-width: 880px;
        min-height: ${styleVars.footerHeight};
        margin-right: auto;
        margin-left: auto;
        padding-top: 32px;
        padding-bottom: 32px;

        @media ${breakPoint.landscape} {
            text-align: center;
        }
    }

    .footer-row {
        display: flex;
        justify-content: space-between;
        margin-top: -20px;
        margin-left: -20px;

        @media ${breakPoint.landscape} {
            display: inline-flex;
            flex-direction: column;
            vertical-align: top;
            text-align: left;
        }
    }

    .footer-col {
        padding-top: 20px;
        padding-left: 20px;
    }

    .footer-list {
        display: table;
        ${resetUl}

        li {
            display: table-row;
            padding-top: 12px;
        }

        .footer-list-cell {
            display: table-cell;

            &:last-child:not(:first-child) {
                padding-left: 16px;
            }
        }

        ${StyledText} {
            padding-top: 12px;
        }
    }
`

export default Footer
