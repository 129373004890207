import React from 'react'
import styled from 'styled-components'
import { styleVars } from '../../theme/theme'

type Props = {
  handleChange: () => void
  checked?: boolean
}

const FormSwitch = ({ checked, handleChange }: Props) => {
  return (
    <StyledSwitch>
      <input type="checkbox" onChange={handleChange} checked={checked} />
      <div className="switch-background" />
      <div className="switch-knob" />
    </StyledSwitch>
  )
}

export const StyledSwitch = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 52px;
  height: 32px;
  border-radius: 16px;

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  .switch-background {
    width: inherit;
    height: inherit;
    border: 1px solid ${styleVars.brandGrayLight};
    border-radius: inherit;
    background-color: ${({ theme }) => (theme.dark ? 'transparent' : styleVars.brandGrayLight)};
  }

  .switch-knob {
    position: absolute;
    display: flex;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    overflow: hidden;
    transition: 100ms;
    transform: ${({ theme }) => (theme.dark ? 'translateX(23px)' : 'translateX(2px)')};
    background-color: ${({ theme }) => (theme.dark ? styleVars.brandGrayLight : '#000')};

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      border-radius: 50%;
      background-color: ${({ theme }) => (theme.dark ? '#000' : styleVars.brandGrayLight)};
    }

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      border-radius: 50%;
      transform: translate(5px, -5px);
      background-color: ${({ theme }) => (theme.dark ? styleVars.brandGrayLight : '#000')};
    }
  }
`

export default FormSwitch
