import React from 'react'
import styled from 'styled-components'
import { Link, GatsbyLinkProps } from 'gatsby'
const element = React.createElement

const BrandLink = (props: GatsbyLinkProps<HTMLAnchorElement | HTMLButtonElement>) => <StyledLink {...props} />

const StyledLink = styled(({ tag, children, ...props }) => element(tag, props, children))`
  &,
  &:hover {
    text-decoration: none;
  }
`

StyledLink.defaultProps = {
  tag: Link
}

export default BrandLink
