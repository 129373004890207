import { createGlobalStyle } from 'styled-components'

import imgBackgroundTop from '../images/background-top.svg'
import imgBackgroundTopSm from '../images/background-top-sm.svg'
import imgBackgroundTopCenterSm from '../images/background-top-center-sm.svg'
import imgBackgroundBottomCenterSm from '../images/background-bottom-center-sm.svg'
import imgBackgroundBottom from '../images/background-bottom.svg'

import { breakPoint, styleVars } from './theme'

type Props = {
  clearPage?: boolean
}

const GlobalStyle = createGlobalStyle`
  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
  
  html {
    font-family: ${styleVars.fontFamilyBase};
    font-size: ${styleVars.fontSizeBase};
    font-weight: ${styleVars.fontWeightBase};
    line-height: ${styleVars.lineHeightBase};
  
    // Reset the box-sizing
    box-sizing: border-box;  
    * {
    &, &:before, &:after {
       box-sizing: inherit;
      }
    }   
  
    // Reset outline 
    * {
      outline: none;
    }
    
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    
    // Reset buttons
    button {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      cursor: pointer;
    }
  }
  
  body {
    color: ${({ theme }) => theme.colorReverce};
    background-color: ${({ theme }) => theme.color};  
    background-image: ${({ clearPage }) => !clearPage && `url('${imgBackgroundTop}'), url('${imgBackgroundBottom}')`};
    background-position: center top ${styleVars.headerHeight}, center bottom;
    background-repeat: no-repeat;
    
    @media ${breakPoint.wide} {
      background-size: 132%;
      background-position: center top ${styleVars.headerHeight}, center bottom ${styleVars.footerHeight};
    }
    
    @media ${breakPoint.tablet} {
      background-size: 100%;
      background-image: ${({ clearPage }) =>
        !clearPage &&
        `url('${imgBackgroundTopSm}'), url('${imgBackgroundTopCenterSm}'), url('${imgBackgroundBottomCenterSm}'), url('${imgBackgroundBottom}')`};
      background-position: center top ${styleVars.headerHeight}, center top 680px, center bottom 992px, center bottom ${
  styleVars.footerHeight
};
    }
  
    -webkit-overflow-scrolling: touch;
  
    // Font smoothing
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }
`

export default GlobalStyle
