import React, { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import 'normalize.css'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { IntlProvider } from 'react-intl'

import en from '../../i18n/messages/en.json'
import ru from '../../i18n/messages/ru.json'
import { themeLight, themeDark } from '../../theme/theme'
import GlobalStyle from '../../theme/global-styles'
import Header from '../header'
import Footer from '../footer'
import Container from '../container'
import { Language } from '../../i18n/types/languages'
import Counters from '../counters'
import useTheme from '../../hooks/theme'

type Props = {
  locale: Language
  children: ReactNode
  clearPage?: boolean
}

const getIntlMessages = (locale: string) => {
  if (locale === 'ru') {
    return ru
  } else {
    return en
  }
}

const Layout = ({ locale, children, clearPage }: Props) => {
  return (
    <IntlProvider locale={locale} messages={getIntlMessages(locale)}>
      <ThemeToggler>
        {({ theme, toggleTheme }) => {
          const darkState = theme === 'dark'
          const themeState = useTheme(darkState)
          return (
            <ThemeProvider theme={themeState ? themeDark : themeLight}>
              <GlobalStyle clearPage={clearPage} />
              {!clearPage && (
                <Header darkState={themeState} changeDarkState={() => toggleTheme(themeState ? 'light' : 'dark')} />
              )}
              <Container>
                <main>{children}</main>
              </Container>
              {!clearPage && <Footer />}
              <Counters />
            </ThemeProvider>
          )
        }}
      </ThemeToggler>
    </IntlProvider>
  )
}

export default Layout
