import React from 'react'
import Helmet from 'react-helmet'

const Counters = () => {
  return (
    <Helmet>
      <script type="text/javascript">
        {`
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(47951645, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true,
            trackHash:true
        });
        `}
      </script>
      <noscript>
        {`
        <div>
          <img src="https://mc.yandex.ru/watch/47951645" style="position:absolute; left:-9999px;" alt="" />
        </div>
        `}
      </noscript>

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-166824143-1"></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'UA-166824143-1');
        `}
      </script>

      <script>
        {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '3213860895398075');
            fbq('track', 'PageView');
            `}
      </script>
      <noscript>
        {`
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=3213860895398075&ev=PageView&noscript=1"/>
        `}
      </noscript>
    </Helmet>
  )
}

export default Counters
