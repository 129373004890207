export const styleVars = {
  // Colors
  brandPrimary: '#FF684C',
  brandPrimaryMedium: '#FF4927',
  brandPrimaryDark: '#FF2800',
  brandPrimaryLight: '#FFB6A9',
  brandSuccess: '#30DC56',
  brandDanger: '#D34338',
  brandGrayLight: '#ECECEC',

  // Fonts
  fontFamilyBase: '"Open Sans", sans-serif',

  fontSizeBase: '16px',
  fontSizeSmall: '14px',
  fontSizeXsmall: '12px',

  fontWeightBase: '400',
  fontWeightBold: '600',
  lineHeightBase: 1.25,

  // Objects
  containerWidth: '1120px',
  containerPadding: '24px',
  headerHeight: '60px',
  footerHeight: '165px'
}

export const themeLight = {
  dark: false,
  color: styleVars.brandGrayLight,
  colorReverce: '#000'
}

export const themeDark = {
  dark: true,
  color: '#000',
  colorReverce: styleVars.brandGrayLight
}

export const breakPoint = {
  wide: '(max-width: 1280px)',
  desktop: '(max-width: 1024px)',
  tablet: '(max-width: 768px)',
  landscape: '(max-width: 576px)',
  phone: '(max-width: 480px)'
}
