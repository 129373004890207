import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { breakPoint, styleVars } from '../../theme/theme'

type Props = {
  children: ReactNode
}

const Container = ({ children }: Props) => <StyledContainer>{children}</StyledContainer>

const StyledContainer = styled.div`
  max-width: calc(${styleVars.containerWidth} + ${styleVars.containerPadding} * 2);
  margin-right: auto;
  margin-left: auto;
  padding-right: ${styleVars.containerPadding};
  padding-left: ${styleVars.containerPadding};

  @media ${breakPoint.tablet} {
    padding-right: calc(${styleVars.containerPadding} / 2);
    padding-left: calc(${styleVars.containerPadding} / 2);
  }

  @media ${breakPoint.phone} {
    padding-right: calc(${styleVars.containerPadding} / 3);
    padding-left: calc(${styleVars.containerPadding} / 3);
  }
`

export default Container
