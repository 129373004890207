import { useEffect, useState } from 'react'

export default function useTheme (darkState: boolean) {
  const [themeState, setThemeState] = useState(false)

  useEffect(() => {
    if (themeState !== darkState) {
      setThemeState(darkState)
    }
  }, [darkState])

  return themeState
}
