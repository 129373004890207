module.exports = {
  en: {
    path: 'en',
    locale: 'Eng'
  },
  ru: {
    path: 'ru',
    locale: 'Ru',
    default: true
  }
}
